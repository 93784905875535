import Swiper from 'swiper';
import Inputmask from 'inputmask';
import Plyr from 'plyr';
import Ellipsis from 'ftellipsis';
import 'core-js/features/global-this';
import 'bootstrap/js/dist/modal';
import 'infinite-scroll/dist/infinite-scroll.pkgd';
import AirDatepicker from 'air-datepicker';

var openNav = () => {
  $('#menu').addClass('show');
  $('.obfuscator').addClass('show');
  $('body').addClass('no-scroll');
}

var closeNav = () => {
  $('#menu').removeClass('show');
  $('.obfuscator').removeClass('show');
  $('body').removeClass('no-scroll');
  $('#service-menu').removeClass('show');
}

var normalizeMenu = () => {
  if (window.innerWidth < 1132) return;
  $('.obfuscator').removeClass('show');
  $('#menu').removeClass('show');
  $('body').removeClass('no-scroll');
  $('#service-menu').removeClass('show');
}

window.recaptchaInit = function() {
  var $el = $('.g-recaptcha')
  if ($el.length) {
    grecaptcha.render($el.get(0), { sitekey: '6LfvOi8iAAAAAFs4BSJaUoLbp5qLcuxk0cZT9pzr' })
  }
}

window.addEventListener('resize', () => normalizeMenu() );

window.addEventListener('DOMContentLoaded', function() {

  //$('#unavailableModal').modal('show');

  $('#menuToggler').on('click', () => openNav());

  $('.obfuscator').on('click', () => closeNav());

  $('#menuCloser').on('click', (e) => {
    e.preventDefault();
    closeNav();
  });

  $('#js-header-dropdown').on('click', function(e) {
    if (window.innerWidth > 1132) return;

    e.preventDefault();
    $($(this).data('target')).toggleClass('show');
  });

  $('.js-collapse').on('click', '.faq-card__question', function(e) {
    var $wrap = $(this).closest('.faq-card')
    $wrap.toggleClass('active')
    var $answer = $wrap.find('.faq-card__answer')
    $answer.slideToggle()
  });

  if (document.querySelector('.swiper-container')) {
    new Swiper('.swiper-container', {
      speed: 300,
      loop: true,
      updateOnWindowResize: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1440: {
          spaceBetween: 40,
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        1200: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 20
        },
        992: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 10
        },
        576: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 10
        }
      }
    })
  }

  Inputmask({alias: 'email', 'showMaskOnHover': false}).mask('input[role=email]');
  Inputmask({mask: '+7(999)999-99-99', 'showMaskOnHover': false}).mask('input[role=phone]');

  $('form #accept').on('change', function() {
    $(this).closest('form').find('input[type=submit]').prop('disabled', !$(this).is(':checked'))
  })

  new AirDatepicker('.datepicker');

  $('.js-anchor').on('click', function(e) {
    const $slideTo = $($(this).attr('href').slice(1) + '-anchor')

    if ($slideTo.length) {
      closeNav();
      $('html, body').animate({scrollTop:  $slideTo.offset().top}, 500)
    }

  })

  if(window.location.hash) {
    const $slideTo = $(window.location.hash + '-anchor')

    if ($slideTo.length) {
      $('html, body').animate({scrollTop:  $slideTo.offset().top}, 500)
    }
  }

  new Plyr('#player', {settings: [], controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']});

  $('.infiniteScroll').infiniteScroll({
    path: '.pagination__next',
    append: '.col-xs-12.col-sm-6.col-lg-3',
    history: false
  });

  $('.ellipsis').each(function(i, el) {
    new Ellipsis(el).calc().set();
  })
})
